<script>
export default {
  name: 'HrbrCkeditorAnnotationEditorModal',

  props: {
    correspondingInput: {
      type: Object,
    },
    inputType: {
      type: String,
    },
  },

  methods: {
    handleInput(text) {
      this.$emit('input', text);
    },

    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<template>
  <div class="hrbr-ckeditor-annotation-editor-modal modal-card animation-content">
    <header class="modal-card-head">
      <p class="modal-card-title">Edit text for "{{ correspondingInput.itemdisplaylabel }}"</p>
    </header>

    <section class="modal-card-body">
      <div class="media">
        <div class="media-content">
          <vue-component-ckeditor
            v-if="['HTMLINPUT', 'BANNERINPUT'].includes(correspondingInput.itemfieldtype)"
            :ondocumentupdate="(correspondingInput, value) => handleInput(value)"
            :item="correspondingInput"
            :disabled="false"
            style="width: 100%; height: 250px">
          </vue-component-ckeditor>
          <b-input
            v-else
            :type="inputType"
            style="margin-bottom: 15px"
            @input="handleInput"
            @keyup.native.enter="closeModal"
            :value="correspondingInput.itemtckvalue"
            icon-pack="fal"
            :icon="correspondingInput.icon">
          </b-input>
        </div>
      </div>
    </section>

    <footer class="modal-card-foot">
      <button type="button" class="button button-confirm" @click="closeModal">
        <span>Done</span>
      </button>
    </footer>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-ckeditor-annotation-editor-modal {
  :deep(.button-confirm) {
    background-color: #2d71ad;
    color: white;
  }
}
</style>
