<script setup>
import { ref, computed } from 'vue';

const emit = defineEmits(['set-role']);
defineProps({
  user: {
    type: Object,
    required: true,
  }
});

const menuRef = ref(null);
const isExpanded = ref(false);
const getUserRole = (role) => {
  const roles = {
    editor: 'Editor',
    viewer: 'Viewer',
  }
  return roles[role];
};

const toggleExpanded = () => (isExpanded.value = !isExpanded.value);
const setRole = (role) => emit('set-role', role);
const handleClickOutside = () => isExpanded.value = false;

const getDropdownPosition = computed(() => {
  const menuEl = menuRef.value;
  if (!menuEl) return {};

  const bounds = menuEl.getBoundingClientRect();
  return {
    top: bounds.bottom + 'px',
    left: bounds.left - 80 + 'px',
  }
});
</script>

<template>
  <div class="role-dropdown-container">
    <div class="user-role" @click.stop.prevent="toggleExpanded" ref="menuRef">
      <i class="fal fa-pencil role-icon" v-if="user.role === 'editor'"></i>
      <i class="fal fa-eye role-icon" v-if="user.role === 'viewer'"></i>
      {{ getUserRole(user.role) }}
      <i class="fas fa-caret-down expander"></i>

      <div class="expanded"
        v-if="isExpanded"
        :style="getDropdownPosition"
        v-click-outside="handleClickOutside">
        <div class="role-row" @click="setRole('editor')">
          <i class="fal fa-pencil role-icon"></i>
          Editor
        </div>
        <div class="role-row" @click="setRole('viewer')">
          <i class="fal fa-eye role-icon"></i>
          Viewer
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.role-dropdown-container {
  position: relative;
}
.user-role {
  display: flex;
  align-items: center;
  margin-right: 10px;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 13px;
  user-select: none;
  cursor: pointer;
  position: relative;
}
.user-role .role-icon {
  margin-right: 8px;
  font-weight: 200;
  font-size: 12px;
  display: flex;
}
.user-role .expander {
  margin-left: 12px;
  font-weight: 600;
  color: black;
  font-size: 20px;
}
.expanded {
  width: 200px;
  position: fixed;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.role-row {
  font-size: 16px;
  padding: 10px 20px;
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  align-items: center;
}
.role-row:hover {
  background-color: #dbdbdb;
}
</style>