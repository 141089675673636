import HrbrCkeditorModal from '../components/Ckeditor/HrbrCkeditorModal.vue';
import HrbrCkeditorShareModal from '../components/Ckeditor/HrbrCkeditorShareModal.vue';
import SuperdocShareModal from '../components/SuperdocContainer/SuperdocShareModal.vue';
import { ModalProgrammatic as Modal } from 'buefy';


/**
 * This plugin is needed to open a modal from any component, even from old code.
 */
function openCkeditorModal(options = {}) {
  const { props = {}, events = {} } = options;

  const modal = Modal.open({
    component: HrbrCkeditorModal,
    parent: props.parent || this,
    fullScreen: true,
    canCancel: false,
    props,
    events,
  });

  return modal;
}

function openCkeditorShareModal(options = {}) {
  const { props = {}, events = {} } = options;

  const modal = Modal.open({
    component: HrbrCkeditorShareModal,
    parent: props.parent || this,
    fullScreen: false,
    canCancel: false,
    props,
    events,
  });

  return modal;
}

function openSuperdocShareModal(options = {}) {
  const { props = {}, events = {} } = options;
  const modal = Modal.open({
    component: SuperdocShareModal,
    parent: props.parent || this,
    fullScreen: false,
    canCancel: true,
    props,
    events,
  });

  return modal;
}

export default {
  install(Vue) {
    Vue.prototype.$openCkeditorModal = openCkeditorModal;
    Vue.prototype.$openCkeditorShareModal = openCkeditorShareModal;
    Vue.prototype.$openSuperdocShareModal = openSuperdocShareModal;
  },
};
