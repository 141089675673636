<script setup>
import axios from 'axios';
import { ref, computed } from 'vue';
import SuperdocDropdown from './SuperdocDropdown.vue';

const emit = defineEmits(['close', 'shared', 'removeCollaborator']);
const props = defineProps({
  superdocId: {
    type: String,
    required: true,
  },
  superdocTitle: {
    type: String,
    required: true,
  },
  collaborators: {
    type: Array,
    required: true,
  }
});

const notifyPeople = ref(true);
const message = ref('');
const linkWasCopied = ref(false);
const initialCollaborators = ref(props.collaborators.map(user => ({ ...user })));
const currentCollaborators = ref(props.collaborators.map(user => ({ ...user })));
const isSubmitting = ref(false);

const invalidEmail = ref(false);
const errorMessage = ref('');

const showCopySuccess = () => {
  linkWasCopied.value = true;
  setTimeout(() => {
    linkWasCopied.value = false;
  }, 2000);
};

const copyLink = () => {
  const url = `${window.location.origin}/document/${props.superdocId}`;
  navigator.clipboard.writeText(url);
  showCopySuccess();
};

const handleShare = async () => {
  isSubmitting.value = true;
  const newEmails = new Set();
  const newCollaborators = currentCollaborators.value.filter((collaborator) => {
    const isNew = !initialCollaborators.value.some((initialCollaborator) => initialCollaborator.email === collaborator.email);
    if (isNew) newEmails.add(collaborator.email);
    return isNew;
  });

  const changedCollaborators = [];
  initialCollaborators.value.forEach((collaborator) => {
    const initialRole = collaborator.role;
    const currentRole = currentCollaborators.value.find((currentCollaborator) => currentCollaborator.email === collaborator.email).role;
    if (initialRole !== currentRole) {
      changedCollaborators.push({
        email: collaborator.email,
        name: collaborator.name,
        role: currentRole,
      });
    }
  });

  const allCollaborators = [...newCollaborators, ...changedCollaborators];
  const promises = [];
  allCollaborators.forEach((collaborator) => {
    promises.push(axios.put(`/api/superdocs/${props.superdocId}/share`, {
      role: collaborator.role,
      user_email: collaborator.email,
      notify_users: notifyPeople.value,
      notification_message: notifyPeople.value ? message.value : null,
    }))
  });

  emit('shared', allCollaborators);
  await Promise.all(promises);
  emit('close');
};

const handleNewRole = (user, role) => {
  user.role = role;
};

const removeUser = async (user) => {
  const userIsNew = !initialCollaborators.value.some((collaborator) => collaborator.email === user.email);
  currentCollaborators.value = currentCollaborators.value.filter((collaborator) => collaborator.email !== user.email);

  if (userIsNew) return;
  emit('removeCollaborator', user);

  initialCollaborators.value = initialCollaborators.value.filter((collaborator) => collaborator.email !== user.email);
  await axios.put(`/api/superdocs/${props.superdocId}/share`, {
    role: null,
    user_email: user.email, 
  });
};

const handleCollaboratorEntry = () => {
  const email = event.target.value;
  if (!email) return;

  const isValid = validateEmail(email);
  if (!isValid) return showError();

  const alreadyExists = currentCollaborators.value.some((collaborator) => collaborator.email === email);
  if (!alreadyExists) {
    currentCollaborators.value.push({
      email: event.target.value,
      name: null,
      role: 'editor',
    });
  };
  event.target.value = '';
}

const showError = () => {
  invalidEmail.value = true;
  errorMessage.value = 'Please enter a valid email address';

  setTimeout(() => {
    resetError();
  }, 5000)
};

const resetError = () => {
  invalidEmail.value = false;
  errorMessage.value = '';
};

const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}
</script>

<template>
  <div class="superdoc-share-modal">

    <div class="superdoc-share-modal-content">
      <div class="superdoc-share-modal-inner">
        <div class="share-header">
          Share {{ superdocTitle }}
        </div>

        <div class="share-input">
          <i class="fal fa-user-plus input-user-icon"></i>
          <input
            type="text"
            placeholder="Add collaborator email"
            @keydown.enter.stop.prevent="handleCollaboratorEntry"
            @input="resetError"
            @blur="handleCollaboratorEntry" />
        </div>
        <div class="error-message">{{ errorMessage }}</div>

        <div class="people-with-access">
          <div class="people-with-access-header">
            <div>People with access</div>
          </div>

          <div class="users-container">
            <div class="user-row" v-for="user in currentCollaborators" :key="user.email">
              <div class="user-side">
                <div class="user-photo">
                  <img :src="user.picture" :alt="user.name" v-if="user.picture">
                  <div class="user-initial" v-else>{{ user.email[0]?.toUpperCase() }}</div>
                </div>
                <div class="user-details">
                  <div class="user-name" v-if="user.name">{{ user.name }}</div>
                  <div class="user-email">{{ user.email }}</div>
                </div>
              </div>
              <div class="user-actions">
                <SuperdocDropdown :user="user" @set-role="handleNewRole(user, $event)" />
                <div class="user-delete" @click="removeUser(user)">
                  <i class="fal fa-times"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="notification-section">
          <div class="notification-section-header">
            <input type="checkbox" v-model="notifyPeople" />
            <div class="notify-btn" @click="notifyPeople = !notifyPeople">Notify people</div>
          </div>

          <div class="notification-message">
            <textarea placeholder="Message" v-model="message"></textarea>
          </div>
        </div>
      </div>

      <div class="separator"></div>

      <div class="superdoc-share-modal-inner">
        <div class="share-buttons">
          <div>
            <button @click="copyLink" v-if="!linkWasCopied">
              <i class="fal fa-link"></i>Copy link
            </button>
            <div class="link-copied-confirmation" v-else>
              <i class="fal fa-check-square" style="margin-right: 5px;"></i>Link copied!
            </div>
          </div>
          <div>
            <button @click="emit('close')" v-if="currentCollaborators.length">Cancel</button>
            <button @click="emit('close')" v-else>Close</button>
            <button class="is-primary" @click="handleShare" :class="{ disabled: !currentCollaborators.length || isSubmitting }">
              <div v-if="!isSubmitting">Share</div>
              <div v-else>
                <i class="fal fa-spinner-third fa-spin" style="margin-right: 5px;"></i>Sharing
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>
.error-message {
  color: #BA2B4A;
  font-size: 13px;
  border-left: 2px solid #BA2B4A;
  padding-left: 10px;
}
.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.superdoc-share-modal {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.superdoc-share-modal-content {
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  background-color: white;
}
.superdoc-share-modal-inner {
  padding: 0 30px;
  width: 600px;
}
.users-container {
  max-height: 400px;
  overflow-y: auto;
}
.people-with-access-header{
  margin-top: 20px;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}
.share-header {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}
.share-input {
  margin-bottom: 15px;
  position: relative;
}
.notification-section {
  margin-top: 40px;
}
.notification-section-header {
  font-size: 14px;
  display: flex;
  align-items: center;
}
.notification-section-header input {
  margin-right: 10px;
}
.superdoc-share-modal .share-input input {
  border-radius: 8px;
  border: 1px solid #ccc;
  height: 40px;
  padding-left: 40px;
  padding-right: 10px;
  transition: all 250ms ease;
  width: 100%;
}
.superdoc-share-modal .share-input input:focus,
.superdoc-share-modal textarea:focus  {
  border: 1px solid #1355FF;
  outline: none; 
}
.input-user-icon {
  position: absolute;
  top: 14px;
  left: 15px;
  font-size: 12px;
}
.share-buttons {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  align-items: center;
}
.link-copied-confirmation {
  font-size: 12px;
}
.superdoc-share-modal button {
  padding: 12px;
  border-radius: 8px;
  outline: none;
  border: 1px solid #dbdbdb;
  font-weight: 400;
  background-color: white;
  cursor: pointer;
  transition: all 250ms ease;
}
.superdoc-share-modal button:hover {
  background-color: #DBDBDB;
}
.superdoc-share-modal button:not(:first-child) {
  margin-left: 5px;
}
.superdoc-share-modal button i {
  margin-right: 5px;
}
.notification-message {
  margin: 20px 0;
}
.superdoc-share-modal textarea {
  outline: none;
  resize: none;
  width: 100%;
  min-height: 150px;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  padding: 10px 15px;
  transition: all 250ms ease;
}
.separator {
  height: 1px;
  background-color: #dbdbdb;
  width: 100%;
  margin: 0;
  padding: 0;
}
.is-primary {
  background-color: #1355FF !important;
  color: white;
}
.is-primary:hover {
  background-color: #0133B7 !important;
  color: white;
}
.user-row {
  display: flex;
  border-top: 1px solid #dbdbdb;
  padding-top: 10px;
  margin-bottom: 10px;
  padding-right: 10px;
  justify-content: space-between;
}

.user-actions {
  display: flex;
  align-items: center;
}
.user-actions .user-delete {
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  transition: all 250ms ease;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-actions .user-delete:hover {
  background-color: #dbdbdb;
}
.user-side {
  display: flex;
  align-items: center;
}
.user-photo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-right: 15px;
}
.user-photo img,
.user-photo .user-initial {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-initial {
  background-color: #dbdbdb;
}

.user-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.user-name {
  font-weight: 600;
  font-size: 13px;
}
.user-email {
  font-size: 13px;
}
</style>